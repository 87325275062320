<template>
    <div>
        <v-autocomplete
            v-model="select"
            :items="items"
            :loading="loading"
            :search-input.sync="search"
            cache-items
            eager
            class="mt-1"
            autofocus
            chips
            :filter="customFilter"
            deletable-chips
            multiple
            item-text="address"
            item-value="id"
            small-chips
            auto-focus
            clearable
            solo
            placeholder="Search by name, address, email, phone"
            prepend-inner-icon="mdi-home-search"
            return-object
            @change="selectAddress"
        >
            <template v-slot:item="data">
                <v-list width="350" class="d-flex">
                    <v-list-item-avatar>
                        <v-avatar size="60" color="primary darken-2">
                            <v-icon large color="primary lighten-2"
                                >mdi-home-map-marker</v-icon
                            >
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title
                            v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                            {{ data.item.address }}
                            {{ data.item.city }},
                            {{ data.item.state }}
                            {{ data.item.zip }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                            v-html="data.item.phone"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-html="data.item.email"
                        ></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list>
            </template>
        </v-autocomplete>
        <!-- Just generate an area to show the type ahead results -->
        <v-card flat :height="height"></v-card>
    </div>
</template>
<script>
export default {
    name: "employer-landlord-lookup-simple-search",
    components: {},
    props: {
        entries: {
            type: Array,
            required: true,
        },
        height: {
            type: Number,
            required: false,
            default: 520,
        },
    },
    data() {
        return {
            loading: false,
            search: null,
            select: null,
        };
    },
    computed: {
        items() {
            return this.search && this.search !== this.select
                ? this.querySelections(this.search)
                : [];
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        customFilter(item, queryText) {
            return (
                item.name.toLowerCase().includes(queryText.toLowerCase()) ||
                item.email.toLowerCase().includes(queryText.toLowerCase()) ||
                item.address.toLowerCase().includes(queryText.toLowerCase()) ||
                item.phone.toLowerCase().includes(queryText.toLowerCase())
            );
        },
        querySelections(queryText) {
            return this.entries.filter((entry) =>
                this.customFilter(entry, queryText)
            );
        },
        selectAddress() {
            this.search = null;
        },
    },
};
</script>

<style scoped>
</style>