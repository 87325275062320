var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "mt-1",
        attrs: {
          items: _vm.items,
          loading: _vm.loading,
          "search-input": _vm.search,
          "cache-items": "",
          eager: "",
          autofocus: "",
          chips: "",
          filter: _vm.customFilter,
          "deletable-chips": "",
          multiple: "",
          "item-text": "address",
          "item-value": "id",
          "small-chips": "",
          "auto-focus": "",
          clearable: "",
          solo: "",
          placeholder: "Search by name, address, email, phone",
          "prepend-inner-icon": "mdi-home-search",
          "return-object": ""
        },
        on: {
          "update:searchInput": function($event) {
            _vm.search = $event
          },
          "update:search-input": function($event) {
            _vm.search = $event
          },
          change: _vm.selectAddress
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(data) {
              return [
                _c(
                  "v-list",
                  { staticClass: "d-flex", attrs: { width: "350" } },
                  [
                    _c(
                      "v-list-item-avatar",
                      [
                        _c(
                          "v-avatar",
                          { attrs: { size: "60", color: "primary darken-2" } },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { large: "", color: "primary lighten-2" }
                              },
                              [_vm._v("mdi-home-map-marker")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", {
                          domProps: { innerHTML: _vm._s(data.item.name) }
                        }),
                        _c("v-list-item-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(data.item.address) +
                              " " +
                              _vm._s(data.item.city) +
                              ", " +
                              _vm._s(data.item.state) +
                              " " +
                              _vm._s(data.item.zip)
                          )
                        ]),
                        _c("v-list-item-subtitle", {
                          domProps: { innerHTML: _vm._s(data.item.phone) }
                        }),
                        _c("v-list-item-subtitle", {
                          domProps: { innerHTML: _vm._s(data.item.email) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.select,
          callback: function($$v) {
            _vm.select = $$v
          },
          expression: "select"
        }
      }),
      _c("v-card", { attrs: { flat: "", height: _vm.height } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }